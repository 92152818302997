<template>
  <b-card-code title="Hospital Management">

    <!-- search input -->
    <div class="custom-search d-flex justify-content-between">
      <div>
        <div class="d-flex align-items-center">
          <b-button variant="success" @click="$router.push({ name: 'admin-hospital-add' })"><feather-icon size="15" icon="PlusIcon"/>&nbsp;Add Hospital</b-button>
        </div>
      </div>
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchText"
            placeholder="Search"
            type="text"
            class="d-inline-block"
            @input="handleSearch"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchText
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      :pagination-options="{
        enabled: true,
      }"
      @on-sort-change="onSortChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Contact Info -->
        <span
          v-if="props.column.field === 'contact_info'"
          class="text-nowrap"
        >
          {{ props.formattedRow[props.column.field] | short-text }}
        </span>

        <!-- Column: Note Info -->
        <span
          v-else-if="props.column.field === 'notes'"
          class="text-nowrap"
        >
          {{ props.formattedRow[props.column.field] | short-text }}
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="handleEdit(props.row)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="handleDelete(props.row)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">

          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageOption.perPage"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> of {{ total }} entries </span>
          </div>

          <!-- pagination -->
          <div>
            <b-pagination
              :value="1"
              :total-rows="total"
              :per-page="pageOption.perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode1.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown, BButton
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BButton
  },
  data() {
    return {
      dir: false,
      total: 0,
      pages: ['3', '5', '10'],
      columns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Hospital ID',
          field: 'hospital_id',
        },
        {
          label: 'Contact email',
          field: 'contact_email',
        },
        {
          label: 'Contact Info',
          field: 'contact_info',
        },
        {
          label: 'Notes',
          field: 'notes',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false
        },
      ],
      rows: [],
      pageOption: {
        page: 1,
        perPage: 5
      },
      sortOption: {
        field: '',
        order: ''
      },
      searchText: ''
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      const { page, perPage } = this.pageOption
      const { field, order } = this.sortOption
      this.$http.get(`/admin/hospitals/list?page=${page}&perPage=${perPage}&field=${field}&order=${order}&searchText=${this.searchText}`)
        .then(res => { 
          this.rows = res.data.result
          this.total = res.data.total
        })
        .catch(err => {
          this.showToast('danger', 'Error', err.response.data.message)
        })
    },
    handleSearch(searching) {
      this.fetchList()
    },
    handleChangePage(page) {
      this.pageOption.page = page
      this.fetchList()
    },
    handlePageChange() {
      this.fetchList()
    },
    onSortChange(params) {
      this.sortOption.field = params[0].field
      this.sortOption.order = params[0].type
      this.fetchList()
    },
    handleDelete(row) {
      this.$swal({
        title: 'Delete this hospital?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`/admin/hospitals/delete/${row._id}`)
            .then(res => {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'The hospital has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.fetchList()
            })
            .catch(err => {
              this.$swal({
                icon: 'error',
                title: 'Error!',
                text: 'An error occured while deleting the hospital.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
    handleEdit(row) {
      this.$router.push({ name: 'admin-hospital-edit', params: { id: row._id } })
    },
    showToast(variant, title, content) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: 'BellIcon',
          text: content,
          variant,
        },
      })
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
